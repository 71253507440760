import { useLanguage } from "@/contexts/LanguageContext";
import { translations } from "@/translations/venues";

interface VenueTranslations {
  title: string;
  description: string;
  capacity?: string;
}

interface VenueCardProps {
  image: string;
  venue: VenueTranslations;
}

const VenueCard = ({ image, venue }: VenueCardProps) => {
  const { language } = useLanguage();
  
  return (
    <div className="relative bg-white">
      <div className="relative aspect-[4/3]">
        <img
          src={image}
          alt={venue.title}
          className="absolute inset-0 w-full h-full object-cover"
          loading="lazy"
        />
      </div>
      <div className="p-6">
        <h3 className={`text-2xl mb-3 text-deepblue ${language !== "ar" ? "font-playfair" : ""}`}>
          {venue.title}
        </h3>
        <p className="text-gray-600 mb-4 text-lg">
          {venue.description}
        </p>
        {venue.capacity && (
          <p className="text-gray-500 text-base">
            {venue.capacity}
          </p>
        )}
      </div>
    </div>
  );
};

export default VenueCard;