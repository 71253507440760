import { useEffect, useState } from "react";
import { useLanguage } from "@/contexts/LanguageContext";
import { supabase } from "@/integrations/supabase/client";
import { useToast } from "@/hooks/use-toast";
import { useInView } from "react-intersection-observer";

interface HeroContent {
  title_en: string;
  title_fr: string;
  title_ar: string;
  title_nl: string;
  subtitle_en: string;
  subtitle_fr: string;
  subtitle_ar: string;
  subtitle_nl: string;
  video_url: string;
}

const Hero = () => {
  const { language } = useLanguage();
  const { toast } = useToast();
  const [videoError, setVideoError] = useState(false);
  const [content, setContent] = useState<HeroContent | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isVideoReady, setIsVideoReady] = useState(false);
  const { ref: videoRef, inView } = useInView({
    threshold: 0,
    triggerOnce: true
  });

  useEffect(() => {
    const fetchHeroContent = async () => {
      console.log('Fetching hero content...');
      try {
        setIsLoading(true);
        const { data, error } = await supabase
          .from('hero_content')
          .select('*')
          .limit(1)
          .single();

        console.log('Hero content response:', { data, error });

        if (error) {
          throw error;
        }

        setContent(data);
      } catch (error) {
        console.error('Error fetching hero content:', error);
        toast({
          variant: "destructive",
          title: "Error",
          description: "Failed to load hero content",
        });
      } finally {
        setIsLoading(false);
      }
    };

    fetchHeroContent();

    const channel = supabase
      .channel('hero_content_changes')
      .on(
        'postgres_changes',
        {
          event: '*',
          schema: 'public',
          table: 'hero_content'
        },
        (payload) => {
          console.log('Received realtime update:', payload);
          if (payload.new) {
            setContent(payload.new as HeroContent);
            toast({
              title: "Content Updated",
              description: "Hero content has been updated",
            });
          }
        }
      )
      .subscribe();

    return () => {
      supabase.removeChannel(channel);
    };
  }, [toast]);

  if (isLoading) {
    return (
      <div className="relative h-screen w-full bg-deepblue flex items-center justify-center">
        <div className="text-white text-xl">Loading...</div>
      </div>
    );
  }

  if (!content) {
    return (
      <div className="relative h-screen w-full bg-deepblue flex items-center justify-center">
        <div className="text-white text-xl">No content available</div>
      </div>
    );
  }

  return (
    <div className="relative h-screen w-full overflow-hidden" ref={videoRef}>
      {/* Background container */}
      <div className="absolute inset-0 w-full h-full">
        {/* Placeholder image */}
        <div 
          className={`absolute inset-0 w-full h-full bg-cover bg-center transition-opacity duration-1000 ${
            isVideoReady ? 'opacity-0' : 'opacity-100'
          }`}
          style={{
            backgroundImage: 'url(/lovable-uploads/e362e1f4-492e-40c4-b1dc-75819381000d.png)',
          }}
        />
        
        {/* Video */}
        {!videoError && content?.video_url && inView && (
          <video
            autoPlay
            muted
            loop
            playsInline
            preload="auto"
            className={`absolute inset-0 w-full h-full object-cover transition-opacity duration-1000 ${
              isVideoReady ? 'opacity-100' : 'opacity-0'
            }`}
            onCanPlayThrough={() => {
              console.log('Video can play through');
              setIsVideoReady(true);
            }}
            onError={(e) => {
              console.error('Video failed to load:', e);
              setVideoError(true);
            }}
          >
            <source src={content.video_url} type="video/mp4" />
          </video>
        )}
      </div>
      
      {/* Overlay */}
      <div className="absolute inset-0 bg-black/40" />
      
      {/* Content */}
      <div className={`relative flex items-center justify-center h-full ${language === "ar" ? "font-arabic" : ""}`}>
        <div className="container mx-auto px-4 text-center">
          <h1 className={`text-4xl md:text-6xl font-bold text-white mb-6 ${language !== "ar" ? "font-playfair" : ""}`}>
            {content[`title_${language}` as keyof HeroContent]}
          </h1>
          <p className="text-xl md:text-2xl text-white/90 max-w-2xl mx-auto">
            {content[`subtitle_${language}` as keyof HeroContent]}
          </p>
        </div>
      </div>
    </div>
  );
};

export default Hero;