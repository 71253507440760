import { useLanguage } from "@/contexts/LanguageContext";
import { useState } from "react";
import {
  Carousel,
  CarouselContent,
  CarouselItem,
  CarouselNext,
  CarouselPrevious,
} from "@/components/Carousel";
import Autoplay from "embla-carousel-autoplay";

interface BedroomCarouselProps {
  images: string[];
  title: {
    en: string;
    fr: string;
    ar: string;
  };
}

const BedroomCarousel = ({ images, title }: BedroomCarouselProps) => {
  const { language } = useLanguage();
  const [api, setApi] = useState<any>(null);
  const autoplay = Autoplay({ delay: 3000, stopOnInteraction: false });

  return (
    <div className="mt-20">
      <h3 className={`text-4xl text-center mb-12 text-deepblue ${language !== "ar" ? "font-playfair" : ""}`}>
        {title[language]}
      </h3>
      <Carousel
        setApi={setApi}
        className="w-full max-w-5xl mx-auto"
        plugins={[autoplay]}
      >
        <CarouselContent>
          {images.map((image, index) => (
            <CarouselItem key={index}>
              <div className="aspect-[16/9] w-full overflow-hidden rounded-xl">
                <img
                  src={image}
                  alt={`Bedroom ${index + 1}`}
                  className="w-full h-full object-cover"
                  loading="lazy"
                />
              </div>
            </CarouselItem>
          ))}
        </CarouselContent>
        <CarouselPrevious />
        <CarouselNext />
      </Carousel>
    </div>
  );
};

export default BedroomCarousel;