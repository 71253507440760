import React, { createContext, useContext, useState, useEffect } from "react";

type Language = "en" | "nl" | "ar";

interface LanguageContextType {
  language: Language;
  setLanguage: (lang: Language) => void;
}

const LanguageContext = createContext<LanguageContextType | undefined>(undefined);

const getInitialLanguage = (): Language => {
  // Get browser/device language
  const browserLang = navigator.language.toLowerCase();
  
  // Check if browser language starts with our supported languages
  // Prioritize Dutch
  if (browserLang.startsWith('nl')) {
    return 'nl';
  } else if (browserLang.startsWith('en')) {
    return 'en';
  } else if (browserLang.startsWith('ar')) {
    return 'ar';
  }
  
  // Default to Dutch
  return 'nl';
};

export const LanguageProvider = ({ children }: { children: React.ReactNode }) => {
  const [language, setLanguage] = useState<Language>(getInitialLanguage());

  useEffect(() => {
    document.documentElement.lang = language;
    document.documentElement.dir = language === 'ar' ? 'rtl' : 'ltr';
  }, [language]);

  return (
    <LanguageContext.Provider value={{ language, setLanguage }}>
      {children}
    </LanguageContext.Provider>
  );
};

export const useLanguage = () => {
  const context = useContext(LanguageContext);
  if (context === undefined) {
    throw new Error("useLanguage must be used within a LanguageProvider");
  }
  return context;
};