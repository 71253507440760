export const translations = {
  title: {
    en: "Our Venues",
    fr: "Nos Lieux",
    nl: "Onze Locaties",
    ar: "أماكننا"
  },
  bedrooms: {
    en: "Bedrooms",
    fr: "Chambres",
    nl: "Slaapkamers",
    ar: "غرف النوم"
  },
  venues: {
    hall: {
      en: {
        title: "The Grand Hall",
        description: "An elegant space for your special moments"
      },
      fr: {
        title: "Le Grand Hall",
        description: "Un espace élégant pour vos moments spéciaux"
      },
      nl: {
        title: "De Grote Zaal",
        description: "Een elegante ruimte voor uw bijzondere momenten"
      },
      ar: {
        title: "القاعة الكبرى",
        description: "مساحة أنيقة للحظاتك الخاصة"
      }
    },
    gardens: {
      en: {
        title: "The Gardens",
        description: "Beautiful outdoor spaces surrounded by nature"
      },
      fr: {
        title: "Les Jardins",
        description: "De beaux espaces extérieurs entourés par la nature"
      },
      nl: {
        title: "De Tuinen",
        description: "Prachtige buitenruimtes omringd door natuur"
      },
      ar: {
        title: "الحدائق",
        description: "مساحات خارجية جميلة محاطة بالطبيعة"
      }
    },
    terrace: {
      en: {
        title: "Moroccan Charm",
        description: "Experience authentic Moroccan architecture and design elements",
      },
      fr: {
        title: "Charme Marocain",
        description: "Découvrez l'architecture et les éléments de design marocains authentiques",
      },
      nl: {
        title: "Marokkaanse Charme",
        description: "Ervaar authentieke Marokkaanse architectuur en designelementen."
      },
      ar: {
        title: "السحر المغربي",
        description: "اكتشف العمارة والتصميم المغربي الأصيل",
      }
    },
    mountainView: {
      en: {
        title: "Mountain View",
        description: "Breathtaking views of the Atlas Mountains"
      },
      fr: {
        title: "Vue sur la Montagne",
        description: "Vue imprenable sur les montagnes de l'Atlas"
      },
      nl: {
        title: "Bergzicht",
        description: "Adembenemend uitzicht op het Atlasgebergte"
      },
      ar: {
        title: "إطلالة على الجبل",
        description: "مناظر خلابة لجبال الأطلس"
      }
    },
    pool: {
      en: {
        title: "The Pool",
        description: "A refreshing oasis for your events"
      },
      fr: {
        title: "La Piscine",
        description: "Une oasis rafraîchissante pour vos événements"
      },
      nl: {
        title: "Het Zwembad",
        description: "Een verfrissende oase voor uw evenementen"
      },
      ar: {
        title: "حمام السباحة",
        description: "واحة منعشة لمناسباتك"
      }
    },
    villa: {
      en: {
        title: "The Villa",
        description: "Luxurious accommodation for your stay"
      },
      fr: {
        title: "La Villa",
        description: "Un hébergement luxueux pour votre séjour"
      },
      nl: {
        title: "De Villa",
        description: "Luxe accommodatie voor uw verblijf"
      },
      ar: {
        title: "الفيلا",
        description: "إقامة فاخرة لإقامتك"
      }
    }
  }
};
