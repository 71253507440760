import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { supabase } from "@/integrations/supabase/client";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs";
import { Upload, Image, Building2, Users } from "lucide-react";
import HeroContentForm from "@/components/admin/HeroContentForm";
import VideoUploader from "@/components/admin/VideoUploader";
import GalleryManager from "@/components/admin/GalleryManager";
import VenuesContentForm from "@/components/admin/VenuesContentForm";
import ExperiencesContentForm from "@/components/admin/ExperiencesContentForm";
import VendorsContentForm from "@/components/admin/VendorsContentForm";

interface HeroContent {
  id?: string;
  title_en: string;
  title_fr: string;
  title_ar: string;
  subtitle_en: string;
  subtitle_fr: string;
  subtitle_ar: string;
}

interface VenuesContent {
  id?: string;
  title_en: string;
  title_fr: string;
  title_ar: string;
  venues: Array<{
    image: string;
    title_en: string;
    title_fr: string;
    title_ar: string;
    description_en: string;
    description_fr: string;
    description_ar: string;
    capacity_en: string;
    capacity_fr: string;
    capacity_ar: string;
  }>;
}

interface ExperiencesContent {
  id?: string;
  title_en: string;
  title_fr: string;
  title_ar: string;
  experiences: Array<{
    image: string;
    title_en: string;
    title_fr: string;
    title_ar: string;
    description_en: string;
    description_fr: string;
    description_ar: string;
  }>;
}

interface VendorsContent {
  id?: string;
  title_en: string;
  title_fr: string;
  title_ar: string;
  vendors: Array<{
    image: string;
    type_en: string;
    type_fr: string;
    type_ar: string;
    name_en: string;
    name_fr: string;
    name_ar: string;
    description_en: string;
    description_fr: string;
    description_ar: string;
  }>;
}

const DEFAULT_HERO_CONTENT: HeroContent = {
  title_en: "Your Dream Wedding in Marrakech",
  title_fr: "Votre Mariage de Rêve à Marrakech",
  title_ar: "حفل زفافك المثالي في مراكش",
  subtitle_en: "Experience luxury and tradition",
  subtitle_fr: "Découvrez le luxe et la tradition",
  subtitle_ar: "اختبر الفخامة والتقاليد"
};

const DEFAULT_VENUES_CONTENT: VenuesContent = {
  title_en: "Our Stunning Venues",
  title_fr: "Nos Lieux Exceptionnels",
  title_ar: "أماكننا المذهلة",
  venues: []
};

const DEFAULT_EXPERIENCES_CONTENT: ExperiencesContent = {
  title_en: "Activities & Experiences",
  title_fr: "Activités & Expériences",
  title_ar: "الأنشطة والتجارب",
  experiences: []
};

const DEFAULT_VENDORS_CONTENT: VendorsContent = {
  title_en: "Recommended Vendors",
  title_fr: "Prestataires Recommandés",
  title_ar: "الموردون الموصى بهم",
  vendors: []
};

const HERO_CONTENT_ID = "5f260f58-ff49-4e3b-aba3-640d8bf3f777";
const VENUES_CONTENT_ID = "6f260f58-ff49-4e3b-aba3-640d8bf3f778";
const EXPERIENCES_CONTENT_ID = "7f260f58-ff49-4e3b-aba3-640d8bf3f779";
const VENDORS_CONTENT_ID = "8f260f58-ff49-4e3b-aba3-640d8bf3f780";

const AdminDashboard = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [heroContent, setHeroContent] = useState<HeroContent>(DEFAULT_HERO_CONTENT);
  const [venuesContent, setVenuesContent] = useState<VenuesContent>(DEFAULT_VENUES_CONTENT);
  const [experiencesContent, setExperiencesContent] = useState<ExperiencesContent>(DEFAULT_EXPERIENCES_CONTENT);
  const [vendorsContent, setVendorsContent] = useState<VendorsContent>(DEFAULT_VENDORS_CONTENT);

  useEffect(() => {
    const checkSession = async () => {
      const { data: { session } } = await supabase.auth.getSession();
      if (!session) {
        navigate("/admin/login");
        return;
      }

      const { data: profile } = await supabase
        .from("profiles")
        .select("role")
        .eq("id", session.user.id)
        .single();

      if (profile?.role !== "admin") {
        navigate("/admin/login");
        return;
      }

      // Load all content
      const [
        { data: heroData },
        { data: venuesData },
        { data: experiencesData },
        { data: vendorsData }
      ] = await Promise.all([
        supabase.from("hero_content").select("*").eq("id", HERO_CONTENT_ID).single(),
        supabase.from("venues_content").select("*").eq("id", VENUES_CONTENT_ID).single(),
        supabase.from("experiences_content").select("*").eq("id", EXPERIENCES_CONTENT_ID).single(),
        supabase.from("vendors_content").select("*").eq("id", VENDORS_CONTENT_ID).single()
      ]);

      if (heroData) setHeroContent(heroData);
      if (venuesData) {
        // Parse the venues JSON data
        const parsedVenues = {
          ...venuesData,
          venues: Array.isArray(venuesData.venues) ? venuesData.venues : JSON.parse(venuesData.venues as string)
        };
        setVenuesContent(parsedVenues);
      }
      if (experiencesData) {
        // Parse the experiences JSON data
        const parsedExperiences = {
          ...experiencesData,
          experiences: Array.isArray(experiencesData.experiences) ? experiencesData.experiences : JSON.parse(experiencesData.experiences as string)
        };
        setExperiencesContent(parsedExperiences);
      }
      if (vendorsData) {
        // Parse the vendors JSON data
        const parsedVendors = {
          ...vendorsData,
          vendors: Array.isArray(vendorsData.vendors) ? vendorsData.vendors : JSON.parse(vendorsData.vendors as string)
        };
        setVendorsContent(parsedVendors);
      }

      setLoading(false);
    };

    checkSession();
  }, [navigate]);

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <div className="container mx-auto p-4">
      <h1 className="text-3xl font-bold mb-6">Admin Dashboard</h1>
      
      <Tabs defaultValue="hero" className="space-y-4">
        <TabsList>
          <TabsTrigger value="hero" className="flex items-center gap-2">
            <Upload size={16} />
            Hero
          </TabsTrigger>
          <TabsTrigger value="venues" className="flex items-center gap-2">
            <Building2 size={16} />
            Venues
          </TabsTrigger>
          <TabsTrigger value="experiences" className="flex items-center gap-2">
            <Image size={16} />
            Experiences
          </TabsTrigger>
          <TabsTrigger value="vendors" className="flex items-center gap-2">
            <Users size={16} />
            Vendors
          </TabsTrigger>
          <TabsTrigger value="gallery" className="flex items-center gap-2">
            <Image size={16} />
            Gallery
          </TabsTrigger>
        </TabsList>

        <TabsContent value="hero" className="space-y-4">
          <div className="grid gap-4 p-4 border rounded-lg">
            <HeroContentForm initialData={heroContent} />
            <VideoUploader />
          </div>
        </TabsContent>

        <TabsContent value="venues" className="space-y-4">
          <div className="grid gap-4 p-4 border rounded-lg">
            <VenuesContentForm initialData={venuesContent} />
          </div>
        </TabsContent>

        <TabsContent value="experiences" className="space-y-4">
          <div className="grid gap-4 p-4 border rounded-lg">
            <ExperiencesContentForm initialData={experiencesContent} />
          </div>
        </TabsContent>

        <TabsContent value="vendors" className="space-y-4">
          <div className="grid gap-4 p-4 border rounded-lg">
            <VendorsContentForm initialData={vendorsContent} />
          </div>
        </TabsContent>

        <TabsContent value="gallery" className="space-y-4">
          <div className="grid gap-4 p-4 border rounded-lg">
            <GalleryManager initialImages={[]} />
          </div>
        </TabsContent>
      </Tabs>
    </div>
  );
};

export default AdminDashboard;