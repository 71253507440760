import { useState } from "react";
import { supabase } from "@/integrations/supabase/client";
import { useToast } from "@/hooks/use-toast";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";

interface GalleryManagerProps {
  initialImages: string[];
}

const GalleryManager = ({ initialImages }: GalleryManagerProps) => {
  const { toast } = useToast();
  const [uploading, setUploading] = useState(false);
  const [galleryImages, setGalleryImages] = useState<string[]>(initialImages);

  const handleGalleryUpload = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files || e.target.files.length === 0) {
      toast({
        variant: "destructive",
        title: "No file selected",
        description: "Please select a file to upload",
      });
      return;
    }

    setUploading(true);

    try {
      const file = e.target.files[0];
      const fileExt = file.name.split('.').pop();
      const fileName = `${Math.random()}.${fileExt}`;

      const { error } = await supabase.storage
        .from("gallery")
        .upload(fileName, file, {
          cacheControl: "3600",
          upsert: false,
        });

      if (error) throw error;

      // Refresh gallery images
      const { data: { publicUrl } } = supabase.storage
        .from("gallery")
        .getPublicUrl("");

      const { data: files } = await supabase.storage
        .from("gallery")
        .list();

      if (files) {
        const imageUrls = files
          .filter(file => file.name.match(/\.(jpg|jpeg|png|gif)$/i))
          .map(file => `${publicUrl}/${file.name}`);
        setGalleryImages(imageUrls);
      }

      toast({
        title: "Success",
        description: "Image uploaded successfully",
      });

      e.target.value = '';
    } catch (error) {
      console.error("Error uploading file:", error);
      toast({
        variant: "destructive",
        title: "Error",
        description: "Failed to upload image",
      });
    } finally {
      setUploading(false);
    }
  };

  return (
    <div className="space-y-4">
      <h2 className="text-xl font-semibold">Gallery Management</h2>
      <div className="space-y-2">
        <Label htmlFor="gallery">Upload Image</Label>
        <Input
          id="gallery"
          type="file"
          accept="image/*"
          onChange={handleGalleryUpload}
          disabled={uploading}
        />
      </div>
      <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4 mt-4">
        {galleryImages.map((url, index) => (
          <div
            key={index}
            className="aspect-square relative overflow-hidden rounded-lg border"
          >
            <img
              src={url}
              alt={`Gallery image ${index + 1}`}
              className="object-cover w-full h-full"
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export default GalleryManager;