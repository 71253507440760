import { useForm } from "react-hook-form";
import { supabase } from "@/integrations/supabase/client";
import { useToast } from "@/hooks/use-toast";
import { Form, FormField, FormItem, FormLabel, FormControl } from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import { Button } from "@/components/ui/button";
import { useState } from "react";

interface VendorsContent {
  id?: string;
  title_en: string;
  title_fr: string;
  title_ar: string;
  vendors: Array<{
    image: string;
    type_en: string;
    type_fr: string;
    type_ar: string;
    name_en: string;
    name_fr: string;
    name_ar: string;
    description_en: string;
    description_fr: string;
    description_ar: string;
  }>;
}

interface VendorsContentFormProps {
  initialData: VendorsContent;
}

const VendorsContentForm = ({ initialData }: VendorsContentFormProps) => {
  const { toast } = useToast();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const form = useForm<VendorsContent>({
    defaultValues: initialData
  });

  const onSubmit = async (data: VendorsContent) => {
    setIsSubmitting(true);
    try {
      const { data: updatedData, error } = await supabase
        .from('vendors_content')
        .upsert({ 
          ...data,
          id: initialData.id || '8f260f58-ff49-4e3b-aba3-640d8bf3f780',
          updated_at: new Date().toISOString()
        })
        .select()
        .single();

      if (error) throw error;

      toast({
        title: "Success",
        description: "Vendors content updated successfully",
      });
    } catch (error) {
      console.error("Error updating vendors content:", error);
      toast({
        variant: "destructive",
        title: "Error",
        description: error instanceof Error ? error.message : "Failed to update vendors content",
      });
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-4">
        <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
          <FormField
            control={form.control}
            name="title_en"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Title (English)</FormLabel>
                <FormControl>
                  <Input {...field} />
                </FormControl>
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="title_fr"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Title (French)</FormLabel>
                <FormControl>
                  <Input {...field} />
                </FormControl>
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="title_ar"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Title (Arabic)</FormLabel>
                <FormControl>
                  <Input {...field} className="text-right" dir="rtl" />
                </FormControl>
              </FormItem>
            )}
          />
        </div>
        <Button type="submit" disabled={isSubmitting}>
          {isSubmitting ? "Saving..." : "Save Vendors Content"}
        </Button>
      </form>
    </Form>
  );
};

export default VendorsContentForm;