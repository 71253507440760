import { createContext, useContext, useState, useEffect, ReactNode } from "react";
import { supabase } from "@/integrations/supabase/client";
import { useToast } from "@/hooks/use-toast";

interface GalleryContextType {
  galleryImages: string[];
  isLoading: boolean;
  error: Error | null;
}

const GalleryContext = createContext<GalleryContextType | undefined>(undefined);

export function GalleryProvider({ children }: { children: ReactNode }) {
  const [galleryImages, setGalleryImages] = useState<string[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<Error | null>(null);
  const { toast } = useToast();

  useEffect(() => {
    const fetchGalleryImages = async () => {
      try {
        const { data: files, error: listError } = await supabase.storage
          .from("gallery")
          .list("Carousel/", {
            limit: 100,
            offset: 0,
            sortBy: { column: 'name', order: 'asc' }
          });

        if (listError) {
          throw listError;
        }

        if (files) {
          const imageUrls = await Promise.all(
            files
              .filter(file => file.name.match(/\.(jpg|jpeg|png|gif)$/i))
              .map(async (file) => {
                const { data: { publicUrl } } = supabase.storage
                  .from("gallery")
                  .getPublicUrl(`Carousel/${file.name}`);
                return publicUrl;
              })
          );
          
          setGalleryImages(imageUrls);
        }
      } catch (err) {
        console.error('Error fetching gallery images:', err);
        setError(err instanceof Error ? err : new Error('Failed to fetch gallery images'));
        toast({
          variant: "destructive",
          title: "Error",
          description: "Failed to load gallery images"
        });
      } finally {
        setIsLoading(false);
      }
    };

    fetchGalleryImages();
  }, [toast]);

  return (
    <GalleryContext.Provider value={{ galleryImages, isLoading, error }}>
      {children}
    </GalleryContext.Provider>
  );
}

export function useGallery() {
  const context = useContext(GalleryContext);
  if (context === undefined) {
    throw new Error('useGallery must be used within a GalleryProvider');
  }
  return context;
}