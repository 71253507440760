import { useLanguage } from "@/contexts/LanguageContext";
import { useGallery } from "@/contexts/GalleryContext";

const translations = {
  title: {
    en: "Activities & Experiences",
    nl: "Activiteiten & Ervaringen",
    ar: "الأنشطة والتجارب"
  },
  experiences: {
    desert: {
      en: {
        title: "Agafay Desert Experience",
        description: "Luxury desert camping and stargazing"
      },
      nl: {
        title: "Agafay Woestijn Ervaring",
        description: "Luxe woestijnkamperen en sterrenkijken"
      },
      ar: {
        title: "تجربة صحراء أغافاي",
        description: "تخييم فاخر في الصحراء ومراقبة النجوم"
      }
    },
    mountains: {
      en: {
        title: "Atlas Mountains Trek",
        description: "Guided tours through scenic mountain paths"
      },
      nl: {
        title: "Atlas Gebergte Tocht",
        description: "Begeleide tours door schilderachtige bergpaden"
      },
      ar: {
        title: "رحلة جبال الأطلس",
        description: "جولات مع مرشد عبر مسارات جبلية خلابة"
      }
    },
    medina: {
      en: {
        title: "Medina Tour",
        description: "Explore the historic heart of Marrakech"
      },
      nl: {
        title: "Medina Rondleiding",
        description: "Verken het historische hart van Marrakech"
      },
      ar: {
        title: "جولة في المدينة القديمة",
        description: "اكتشف القلب التاريخي لمراكش"
      }
    }
  }
};

const Gallery = () => {
  const { language } = useLanguage();
  const { galleryImages, isLoading } = useGallery();

  const experiences = [
    {
      image: "https://sleddqxnzjehpqcpobmy.supabase.co/storage/v1/object/public/gallery/Agafay.png",
      ...translations.experiences.desert[language]
    },
    {
      image: "https://sleddqxnzjehpqcpobmy.supabase.co/storage/v1/object/public/gallery/Trail.jpeg",
      ...translations.experiences.mountains[language]
    },
    {
      image: "https://sleddqxnzjehpqcpobmy.supabase.co/storage/v1/object/public/gallery/Medina%20copy.png",
      ...translations.experiences.medina[language]
    }
  ];

  if (isLoading) {
    return (
      <section className="bg-white">
        <div className="mx-auto">
          <div className="text-center">Loading...</div>
        </div>
      </section>
    );
  }

  return (
    <section className="bg-white">
      <div className="mx-auto">
        <h2 className={`text-5xl text-center mb-16 text-deepblue ${language !== "ar" ? "font-playfair" : ""}`}>
          {translations.title[language]}
        </h2>
        
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 md:gap-6 lg:gap-8 md:px-6 lg:px-8">
          {experiences.map((experience, index) => (
            <div
              key={index}
              className="relative bg-white"
            >
              <div className="relative aspect-[4/3]">
                <img
                  src={experience.image}
                  alt={experience.title}
                  className="absolute inset-0 w-full h-full object-cover"
                />
              </div>
              <div className="p-6">
                <h3 className={`text-2xl mb-3 text-deepblue ${language !== "ar" ? "font-playfair" : ""}`}>
                  {experience.title}
                </h3>
                <p className="text-gray-600 text-lg">
                  {experience.description}
                </p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Gallery;