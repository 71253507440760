import { useEffect, useState } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { Button } from "@/components/ui/button";
import { supabase } from "@/integrations/supabase/client";
import { Phone } from "lucide-react";
import type { User } from "@supabase/supabase-js";
import LanguageSelector from "./LanguageSelector";

const Navbar = () => {
  const [user, setUser] = useState<User | null>(null);
  const [isAdmin, setIsAdmin] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    // Initial session check
    const checkSession = async () => {
      const { data: { session } } = await supabase.auth.getSession();
      setUser(session?.user ?? null);
      
      if (session?.user) {
        const { data: profile } = await supabase
          .from("profiles")
          .select("role")
          .eq("id", session.user.id)
          .single();
        
        const isUserAdmin = profile?.role === "admin";
        setIsAdmin(isUserAdmin);
        
        if (!isUserAdmin && location.pathname.startsWith("/admin")) {
          navigate("/admin/login");
        }
      }
    };

    checkSession();

    const { data: { subscription } } = supabase.auth.onAuthStateChange(
      async (event, session) => {
        console.log("Auth state changed:", event, session?.user?.email);
        setUser(session?.user ?? null);
        
        if (session?.user) {
          const { data: profile } = await supabase
            .from("profiles")
            .select("role")
            .eq("id", session.user.id)
            .single();
          
          const isUserAdmin = profile?.role === "admin";
          setIsAdmin(isUserAdmin);
          
          if (!isUserAdmin && location.pathname.startsWith("/admin")) {
            navigate("/admin/login");
          }
        } else if (location.pathname.startsWith("/admin")) {
          navigate("/admin/login");
        }
      }
    );

    return () => subscription.unsubscribe();
  }, [navigate, location.pathname]);

  const handleSignOut = async () => {
    await supabase.auth.signOut();
    navigate("/admin/login");
  };

  return (
    <nav className="absolute top-0 left-0 right-0 z-50 bg-transparent">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex items-center justify-between h-20">
          <div className="flex items-center gap-4">
            <a 
              href="tel:+31643200275" 
              className="flex items-center gap-2 text-white hover:text-white/80"
            >
              <Phone className="h-4 w-4" />
              <span>+31 6 43200275</span>
            </a>
            <Link to="/" className="font-playfair text-2xl font-bold text-white">
            </Link>
          </div>
          <div className="flex items-center gap-4">
            <LanguageSelector />
            {user && isAdmin && (
              <Button
                variant="outline"
                className="text-white hover:text-white/80 border-white"
                onClick={handleSignOut}
              >
                Sign Out
              </Button>
            )}
          </div>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;