import { createRoot } from 'react-dom/client'
import App from './App.tsx'
import './index.css'

console.log('Starting React application...');

const rootElement = document.getElementById("root");
console.log('Root element found:', rootElement);

if (!rootElement) {
  console.error('Failed to find root element');
} else {
  const root = createRoot(rootElement);
  console.log('Created React root');
  
  try {
    root.render(<App />);
    console.log('App rendered successfully');
  } catch (error) {
    console.error('Error rendering app:', error);
  }
}