import { useLanguage } from "@/contexts/LanguageContext";
import { useGallery } from "@/contexts/GalleryContext";
import { useTranslations } from "@/contexts/TranslationsContext";

const Gallery = () => {
  const { language } = useLanguage();
  const { galleryImages, isLoading: galleryLoading } = useGallery();
  const { translations, isLoading: translationsLoading } = useTranslations();

  if (translationsLoading || galleryLoading) {
    return (
      <section className="bg-white">
        <div className="mx-auto">
          <div className="text-center">Loading...</div>
        </div>
      </section>
    );
  }

  const experiences = [
    {
      image: "https://sleddqxnzjehpqcpobmy.supabase.co/storage/v1/object/public/gallery/Agafay.png",
      ...translations.experiences.desert
    },
    {
      image: "https://sleddqxnzjehpqcpobmy.supabase.co/storage/v1/object/public/gallery/Trail.jpeg",
      ...translations.experiences.mountains
    },
    {
      image: "https://sleddqxnzjehpqcpobmy.supabase.co/storage/v1/object/public/gallery/Medina%20copy.png",
      ...translations.experiences.medina
    }
  ];

  return (
    <section className="bg-white">
      <div className="mx-auto">
        <h2 className={`text-5xl text-center mb-16 text-deepblue ${language !== "ar" ? "font-playfair" : ""}`}>
          {translations.common.experiences_title}
        </h2>
        
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 md:gap-6 lg:gap-8 md:px-6 lg:px-8">
          {experiences.map((experience, index) => (
            <div
              key={index}
              className="relative bg-white"
            >
              <div className="relative aspect-[4/3]">
                <img
                  src={experience.image}
                  alt={experience.title}
                  className="absolute inset-0 w-full h-full object-cover"
                />
              </div>
              <div className="p-6">
                <h3 className={`text-2xl mb-3 text-deepblue ${language !== "ar" ? "font-playfair" : ""}`}>
                  {experience.title}
                </h3>
                <p className="text-gray-600 text-lg">
                  {experience.description}
                </p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Gallery;