import { useLanguage } from "@/contexts/LanguageContext";
import { Button } from "@/components/ui/button";

const LanguageSelector = () => {
  const { language, setLanguage } = useLanguage();

  return (
    <div className="flex items-center gap-2">
      <Button 
        variant="ghost" 
        size="sm"
        onClick={() => setLanguage("en")}
        className={`text-white hover:text-white/80 ${language === "en" ? "underline" : ""}`}
      >
        EN
      </Button>
      <Button 
        variant="ghost" 
        size="sm"
        onClick={() => setLanguage("nl")}
        className={`text-white hover:text-white/80 ${language === "nl" ? "underline" : ""}`}
      >
        NL
      </Button>
      <Button 
        variant="ghost" 
        size="sm"
        onClick={() => setLanguage("ar")}
        className={`text-white hover:text-white/80 ${language === "ar" ? "underline" : ""}`}
      >
        ع
      </Button>
    </div>
  );
};

export default LanguageSelector;