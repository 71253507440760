import { useState } from "react";
import { supabase } from "@/integrations/supabase/client";
import { useToast } from "@/hooks/use-toast";
import { Input } from "@/components/ui/input";
import { Button } from "@/components/ui/button";
import { Label } from "@/components/ui/label";

const VideoUploader = () => {
  const { toast } = useToast();
  const [uploading, setUploading] = useState(false);
  const [selectedFile, setSelectedFile] = useState<File | null>(null);

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files[0]) {
      setSelectedFile(e.target.files[0]);
    }
  };

  const handleUpload = async () => {
    if (!selectedFile) {
      toast({
        variant: "destructive",
        title: "No file selected",
        description: "Please select a file to upload",
      });
      return;
    }

    setUploading(true);

    try {
      const { error } = await supabase.storage
        .from("videos")
        .upload("hero.mp4", selectedFile, {
          cacheControl: "3600",
          upsert: true,
        });

      if (error) throw error;

      toast({
        title: "Success",
        description: "File uploaded successfully",
      });

      setSelectedFile(null);
    } catch (error) {
      console.error("Error uploading file:", error);
      toast({
        variant: "destructive",
        title: "Error",
        description: "Failed to upload file",
      });
    } finally {
      setUploading(false);
    }
  };

  return (
    <div className="space-y-2">
      <h3 className="text-lg font-semibold">Update Hero Video</h3>
      <Label htmlFor="video">Select Video File</Label>
      <Input
        id="video"
        type="file"
        accept="video/mp4"
        onChange={handleFileChange}
        disabled={uploading}
      />
      {selectedFile && (
        <div className="text-sm text-gray-600">
          Selected file: {selectedFile.name}
        </div>
      )}
      <Button
        onClick={handleUpload}
        disabled={!selectedFile || uploading}
        className="w-full"
      >
        {uploading ? "Uploading..." : "Upload Video"}
      </Button>
    </div>
  );
};

export default VideoUploader;